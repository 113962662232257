<template>
  <div>
    <section class="section">
      <div class="container mt-60">
        <div class="row">
          <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4">
            <library :content.sync="content" />
          </div>
          <div class="col-xl-9 col-lg-8 col-md-8 col-sm-8">
            <div class="section-title">
              <div class="media features">
                <div class="icons m-0 rounded h1 text-primary px-3">
                  <i class="uil uil-notes"></i>
                </div>
              </div>
              <h5 class="title">จัดการเนื้อหาประเภทข้อสอบ (Examination)</h5>
              <p class="border-bottom"></p>
              <h5 class="text-primary">ประเภทเติมคำ (Fill the blank)</h5>
            </div>

            <div class="row justify-content-center">
              <div class="col-12">
                <b-tabs
                  pills
                  justified
                  content-class="mt-4 pt-2"
                  nav-wrapper-class=""
                >
                  <b-tab>
                    <template v-slot:title>
                      <div class="text-center pt-1 pb-1">
                        <p class="title font-weight-normal mb-0">เพิ่มคำถาม</p>
                      </div>
                    </template>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">1</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">กดปุ่ม "New Question"</h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal1>
                          <img
                            src="/images/screen/course/exam/filltheblank/create/1.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal1"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/filltheblank/create/1.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">2</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">เลือก "Fill the Blank"</h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal2>
                          <img
                            src="/images/screen/course/exam/filltheblank/create/2.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal2"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/filltheblank/create/2.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">3</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">เพิ่มคำถามลงในแบบฟอร์ม</h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal3>
                          <img
                            src="/images/screen/course/exam/filltheblank/create/3.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal3"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/filltheblank/create/3.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">4</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              กำหนดเปอเซ็นต์ความถูกต้องในข้อความที่จะใช้ในการตรวจสอบ
                              และกำหนดคะแนน
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal4>
                          <img
                            src="/images/screen/course/exam/filltheblank/create/4.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal4"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/filltheblank/create/4.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">5</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">กดปุ่ม "Add Answers"</h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal5>
                          <img
                            src="/images/screen/course/exam/filltheblank/create/5.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal5"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/filltheblank/create/5.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">6</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              เพิ่มคำตอบที่จะใช้ตรวจสอบในแบบฟอร์ม
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal6>
                          <img
                            src="/images/screen/course/exam/filltheblank/create/6.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal6"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/filltheblank/create/6.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">7</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">กดปุ่มบันทึก</h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal7>
                          <img
                            src="/images/screen/course/exam/filltheblank/create/7.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal7"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/filltheblank/create/7.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">8</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              เพิ่มคำอธิบายคำตอบลงในแบบฟอร์ม
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal8>
                          <img
                            src="/images/screen/course/exam/filltheblank/create/8.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal8"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/filltheblank/create/8.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">9</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">กดปุ่มบันทึก</h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal9>
                          <img
                            src="/images/screen/course/exam/filltheblank/create/9.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal9"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/filltheblank/create/9.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                  </b-tab>
                  <b-tab>
                    <template v-slot:title>
                      <div class="text-center pt-1 pb-1">
                        <p class="title font-weight-normal mb-0">แก้ไขคำถาม</p>
                      </div>
                    </template>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">1</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              กดปุ่ม "edit" หลังคำถามที่เลือก
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal10>
                          <img
                            src="/images/screen/course/exam/filltheblank/edit/1.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal10"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/filltheblank/edit/1.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">2</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">แก้ไขคำถามลงในแบบฟอร์ม</h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal11>
                          <img
                            src="/images/screen/course/exam/filltheblank/edit/2.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal11"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/filltheblank/edit/2.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">3</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              แก้ไขเปอเซ็นต์ความถูกต้องในข้อความที่จะใช้ในการตรวจสอบ
                              และกำหนดคะแนน
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal12>
                          <img
                            src="/images/screen/course/exam/filltheblank/edit/3.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal12"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/filltheblank/edit/3.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">4</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              กดปุ่ม "Edit" หลังคำตอบที่ต้องการแก้ไข
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal13>
                          <img
                            src="/images/screen/course/exam/filltheblank/edit/4.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal13"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/filltheblank/edit/4.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">5</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">แก้ไขคำตอบลงในแบบฟอร์ม</h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal14>
                          <img
                            src="/images/screen/course/exam/filltheblank/edit/5.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal14"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/filltheblank/edit/5.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">6</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">กดปุ่มบันทึก</h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal15>
                          <img
                            src="/images/screen/course/exam/filltheblank/edit/6.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal15"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/filltheblank/edit/6.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">7</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              กดปุ่ม "Delete" หลังคำตอบที่ต้องการลบ
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal16>
                          <img
                            src="/images/screen/course/exam/filltheblank/edit/7.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal16"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/filltheblank/edit/7.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">8</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              กดปุ่ม "CONFIRM" ยืนยันการลบ
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal17>
                          <img
                            src="/images/screen/course/exam/filltheblank/edit/8.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal17"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/filltheblank/edit/8.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">9</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              แก้ไขคำอธิบายคำตอบลงในแบบฟอร์ม
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal18>
                          <img
                            src="/images/screen/course/exam/filltheblank/edit/9.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal18"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/filltheblank/edit/9.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">10</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">กดปุ่มบันทึก</h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal19>
                          <img
                            src="/images/screen/course/exam/filltheblank/edit/10.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal19"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/filltheblank/edit/10.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                  </b-tab>
                  <b-tab>
                    <template v-slot:title>
                      <div class="text-center pt-1 pb-1">
                        <p class="title font-weight-normal mb-0">ลบคำถาม</p>
                      </div>
                    </template>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">1</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              กดปุ่ม "Delete" หลังคำถามที่ต้องการลบ
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal20>
                          <img
                            src="/images/screen/course/exam/filltheblank/delete/1.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal20"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/filltheblank/delete/1.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">2</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              กดปุ่ม "CONFIRM" ยืนยันการลบ
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal21>
                          <img
                            src="/images/screen/course/exam/filltheblank/delete/2.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal21"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/filltheblank/delete/2.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                  </b-tab>
                </b-tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import library from "@/views/library/library.vue";
export default {
  data() {
    return {
      content: "lectureExamFillTheBlank",
    };
  },
  components: {
    library,
  },
};
</script>
